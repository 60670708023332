<template>
  <Modal
    v-model="mostrarModal"
    :title="'Vista previa del documento'"
    :modal-style="{ 'min-width': '300px', 'max-width': '1140px' }"
    
    @close="cerrarModal"
  >
    <CRow>
      <CCol sm="6" md="6" lg="6">
        <!--<CButton
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="descargamodal"
          ><CIcon
            :content="$options.freeSet.cilCloudDownload"
            v-c-tooltip.hover="{
              content: 'Descargar Documento',
              placement: 'over',
            }"
          />
        </CButton>-->
        
      </CCol>
      <CCol sm="6" md="6" lg="6">
        <!--<CButton
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="rechazarDocumentos"
          :disabled="(trabajadorprev != null)?((trabajadorprev.documento.hayDocumento)?trabajadorprev.documento.estadoDocumento == 2 || trabajadorprev.documento.estadoDocumento == 0:false) : false"
        >Rechazar Documento</CButton>
        
        <CButton
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="quitarRechazoDocumento"
          v-show="(trabajadorprev != null)?trabajadorprev.documento.estadoDocumento == 2:false"
          >Quitar Rechazado</CButton>-->
        </CCol>
    </CRow>
    <!--<iframe
      :src="documento.link"
      width="100%"
      height="400"
      allow="autoplay"
    ></iframe>-->
    <CRow>
      <CCol sm="12" md="12" lg="12">
        <CButton
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="cerrarModal"
          >Cerrar</CButton
        ></CCol
      ></CRow
    >
  </Modal>
  
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import cons from "@/const";
import Multiselect from "vue-multiselect";
import axios from "axios";

import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";

import { freeSet } from "@coreui/icons";

export default {
  name: "VisorDocumentos",
  freeSet,
  props: {
    mostrarModal: Boolean,
    documento: Object,
    webPadre: Object
  },
  components: {
    Multiselect,
    Modal: VueModal,
  },
  data() {
    return {

      contador: 0,
      derizq: null,
      estado: null,
      haydoc: true,
      haydocsig: true,

      isLoadingTrabajadores: false,

      trabajadoresModal: [],

      currentPage: 1,
      totalPages: 20,
      
      strFiltroTrabajadores: "",

      rqstTrabs: null,

      

    };
  },
  created: function () {
  },
  beforeMount() {
    let cookieUsername = this.$cookie.get("userLogginToken");
    if (cookieUsername === null || cookieUsername === "") {
      this.$router.push("/pages/login");
    }
    //this.trabajadorprev = this.trabajador;
    
  },
  methods: {
    
    

    descargamodal: function () {
      var link = this.trabajadorprev.documento.urlDocumento;
      var fileLink = document.createElement("a");
      fileLink.href = link;
      fileLink.setAttribute("download", this.trabajadorprev.documento.nombreDocumento);
      document.body.appendChild(fileLink);
      fileLink.click();
    },

    cerrarModal: function () {
      this.web.mostrarVisorPDF = false;
    },
  },
};
</script>